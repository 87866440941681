import './common.js'
import 'owl.carousel';
import 'jquery-nice-select'
import 'magnific-popup'
import WOW from 'wowjs'
(function ($) {
    "use strict";
    $(document).ready(function () {
        jQuery(document).ready(function ($) {
            $(document).on('submit', '#contact_form_submit', function (e) {
                e.preventDefault();
                const name = $('#name').val();
                const email = $('#email').val();
                const subject = $('#subject').val();
                const message = $('#message').val();

                if (name && email && message) {
                    $.ajax({
                        type: "POST",
                        url: 'https://formsubmit.co/ajax/6954418b41e40bf67dc564b309799a84',
                        data: {
                            'name': name,
                            'email': email,
                            'subject': subject,
                            'message': message,
                        },
                        success: function (data) {
                            $('#contact_form_submit').children('.email-success').remove();
                            $('#contact_form_submit').prepend('<span class="alert alert-success email-success">' + JSON.parse(data).message + '</span>');
                            $('#name').val('');
                            $('#email').val('');
                            $('#message').val('');
                            $('#subject').val('');
                            // $('#map').height('576px');
                            $('.email-success').fadeOut(3000);
                        },
                        error: function (res) {
                            alert(res.message)
                        }
                    });
                } else {
                    $('#contact_form_submit').children('.email-success').remove();
                    $('#contact_form_submit').prepend('<span class="alert alert-danger email-success">All Fields are Required.</span>');
                    // $('#map').height('576px');
                    $('.email-success').fadeOut(3000);
                }

            });
        })
        // Nice Select
        $('.select-bar').niceSelect();
        // PoPuP

        $('.popup').magnificPopup({
            disableOn: 700,
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: true,
            fixedContentPos: false,

        });
        $("body").each(function () {
            $(this).find(".img-pop").magnificPopup({
                type: "image",
                gallery: {
                    enabled: true
                }
            });
        });
        // aos js activ
        const d= new WOW.WOW({
            live: false
        })
        d.init()

        //Faq
        $('.faq-wrapper .faq-title').on('click', function (e) {
            const element = $(this).parent('.faq-item');
            if (element.hasClass('open')) {
                element.removeClass('open');
                element.find('.faq-content').removeClass('open');
                element.find('.faq-content').slideUp(300, "swing");
            } else {
                element.addClass('open');
                element.children('.faq-content').slideDown(300, "swing");
                element.siblings('.faq-item').children('.faq-content').slideUp(300, "swing");
                element.siblings('.faq-item').removeClass('open');
                element.siblings('.faq-item').find('.faq-title').removeClass('open');
                element.siblings('.faq-item').find('.faq-content').slideUp(300, "swing");
            }
        });
        //Faq
        $('.faq--area .faq-title').on('click', function (e) {
            const element = $(this).parent('.faq--item');
            if (element.hasClass('open')) {
                element.removeClass('open');
                element.find('.faq-content').removeClass('open');
                element.find('.faq-content').slideUp(300, "swing");
            } else {
                element.addClass('open');
                element.children('.faq-content').slideDown(300, "swing");
                element.siblings('.faq--item').children('.faq-content').slideUp(300, "swing");
                element.siblings('.faq--item').removeClass('open');
                element.siblings('.faq--item').find('.faq-title').removeClass('open');
                element.siblings('.faq--item').find('.faq-content').slideUp(300, "swing");
            }
        });
        //Menu Dropdown Icon Adding
        $("ul>li>.submenu").parent("li").addClass("menu-item-has-children");
        // drop down menu width overflow problem fix
        $('.submenu').parent('li').hover(function () {
            const menu = $(this).find("ul");
            const menupos = $(menu).offset();
            if (menupos.left + menu.width() > $(window).width()) {
                const newpos = -$(menu).width();
                menu.css({
                    left: newpos
                });
            }
        });
        $('.menu li a').on('click', function (e) {
            const element = $(this).parent('li');
            if (element.hasClass('open')) {
                element.removeClass('open');
                element.find('li').removeClass('open');
                element.find('ul').slideUp(300, "swing");
            } else {
                element.addClass('open');
                element.children('ul').slideDown(300, "swing");
                element.siblings('li').children('ul').slideUp(300, "swing");
                element.siblings('li').removeClass('open');
                element.siblings('li').find('li').removeClass('open');
                element.siblings('li').find('ul').slideUp(300, "swing");
            }
        })
        //Widget Slider
        // Scroll To Top
        const scrollTop = $(".scrollToTop");
        $(window).on('scroll', function () {
            if ($(this).scrollTop() < 500) {
                scrollTop.removeClass("active");
            } else {
                scrollTop.addClass("active");
            }
        });
        //Click event to scroll to top
        $('.scrollToTop').on('click', function () {
            $('html, body').animate({
                scrollTop: 0
            }, 500);
            return false;
        });
        //Header Bar
        $('.header-bar').on('click', function () {
            $(this).toggleClass('active');
            $('.overlay').toggleClass('active');
            $('.menu').toggleClass('active');
        })
        $('.overlay').on('click', function () {
            $(this).removeClass('active');
            $('.header-bar').removeClass('active');
            $('.menu').removeClass('active');
        })
        // Header Sticky Hereconst prevScrollpos = window.pageYOffset;

        const header = $(".header-section");
        $(window).on('scroll', function () {
            if ($(this).scrollTop() < 1) {
                header.removeClass("active");
            } else {
                header.addClass("active");
            }
        });
        const scrollPosition = window.scrollY;
        if (scrollPosition >= 1) {
            header.addClass("active");
            $(".header-bottom").addClass('active');
            $(".header-section-2").removeClass('plan-header');
        }

        $('.tab ul.tab-menu li').on('click', function (g) {
            const tab = $(this).closest('.tab'),
                index = $(this).closest('li').index();
            tab.find('li').siblings('li').removeClass('active');
            $(this).closest('li').addClass('active');
            tab.find('.tab-area').find('div.tab-item').not('div.tab-item:eq(' + index + ')').hide(10);
            tab.find('.tab-area').find('div.tab-item:eq(' + index + ')').fadeIn(10);
            g.preventDefault();
        });
        $('.tab-up ul.tab-menu li').on('click', function (g) {
            const tabT = $(this).closest('.tab-up'),
                indexT = $(this).closest('li').index();
            tabT.find('li').siblings('li').removeClass('active');
            $(this).closest('li').addClass('active');
            tabT.find('.tab-area').find('div.tab-item').not('div.tab-item:eq(' + indexT + ')').slideUp(400);
            tabT.find('.tab-area').find('div.tab-item:eq(' + indexT + ')').slideDown(400);
            g.preventDefault();
        });

        const owlFeat = $('.feat-slider');

        owlFeat.owlCarousel({
            center: true,
            items: 1,
            // autoplay: true,
            // autoplayTimeout: 3000,
            loop: true,
            margin: 0,
            singleItem: true,
            nav: false,
            dots: false,
            dotsContainer: '.dots-2',
            thumbs: false,
            mouseDrag: false,
            touchDrag: false,
            thumbsPrerendered: true,
            animateOut: 'fadeOut',
            animateIn: 'fadeIn',
        });


        const scrOwl = $('.screenshot-slider-2').owlCarousel({
            items: 1,
            loop: false,
            margin: 30,
            singleItem: true,
            nav: false,
            dots: true,
            dotsContainer: '.dots-2'
        });
        scrOwl.on('changed.owl.carousel', function({item}) {
            owlFeat.trigger('to.owl.carousel', [item.index, 100])
        })

        $('.up--down--overflow').prev('*').addClass('pb-lg-200');
        $('.up--down--overflow').next('*').addClass('pt-lg-200');
        $('.up--down--overflow').addClass('pt-lg-0 pb-lg-0');
    });
})(jQuery);
